import '../styles/NotFoundPage.css'; // Import your CSS file

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h2>404</h2>
        <p>Sorry, your trying to access token may got expired or Token details not present.</p>
      </div>
    </div>
  );
};

export default NotFoundPage;
